
import {rzButton} from 'razlet-ui';

export default {
  name: 'popular-directions',
  components: {
    rzButton,
  },
  data() {
    return {
      directions: [
        { 
          from: {code: 'VEO', name: 'Северо-Енисейск'}, 
          to: {code: 'KJA', name: 'Красноярск'},
        },
        { 
          from: {code: 'KJA', name: 'Красноярск'}, 
          to: {code: 'THX', name: 'Туруханск'},
        },
        { 
          from: {code: 'KJA', name: 'Красноярск'}, 
          to: {code: 'VEO', name: 'Северо-Енисейск'},
        },
      ],
    };
  },
  methods: {
    goToDirection(direction) {
      this.$emit('select', direction);
      // this.$router.replace({ name: 'index', query: {direction: JSON.stringify(direction)} });
    },
  },
};
